@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400;600&display=swap');

body {
  font-family: 'Source Sans Pro', sans-serif !important;
  background: lightgray;
  -moz-transform: scale(0.8, 0.8);
  /* Moz-browsers */
  zoom: 0.8;
  /* Other non-webkit browsers */
  zoom: 80%;
  /* Webkit browsers */
}

/* Apply styles to all scrollbars */
* {
  /* //scrollbar-width: thin;
  scrollbar-color: #0E86D4 transparent; */
}

/* WebKit-based browsers */
*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-thumb {
  background-color: #0E86D4;
  border-radius: 15px !important;
  height: 7px !important;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #0E86D4;
}

*::-webkit-scrollbar-track {
  background-color: transparent !important;
}

/* Apply styles to horizontal scrollbar */
*::-webkit-scrollbar-horizontal {
  height: 7px;
  background-color: transparent !important;
}

.product-slider-container::-webkit-scrollbar-horizontal {
  height: 7px;
}

*::-webkit-scrollbar-thumb:horizontal {
  background-color: #0E86D4;
}

*::-webkit-scrollbar-thumb:horizontal:hover {
  background-color: #0E86D4;
}

*::-webkit-scrollbar-track:horizontal {
  background-color: #f1f1f1;
}

.border-radius-0 {
  border-radius: 0px !important;
}

.form-control:focus {
  box-shadow: none !important;
  outline: none !important;
}

.my-card {
  display: grid;
  gap: 15px;
  grid-template-columns: 19% 19% 19% 19% 19%;
  margin-top: 15px !important;
}

.grid-col-row-1 {
  display: grid;
  grid-column: 1;
  grid-row: 1/span 2;
  text-align: center;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
}

.i-amphtml-layout-flex-item,
[layout=flex-item]:not(.i-amphtml-layout-flex-item) {
  display: block;
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

.i-amphtml-layout-fluid {
  position: relative
}

.i-amphtml-layout-size-defined {
  overflow: hidden !important
}

.i-amphtml-layout-awaiting-size {
  position: absolute !important;
  top: auto !important;
  bottom: auto !important
}

i-amphtml-sizer {
  display: block !important
}

.btn-login {
  background: #fff;
}

.btn-signup {
  width: 99px;
  border: 1px solid #fff;
  padding: 8px 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-left: 9px;
}

.btn-signup a {
  text-decoration: none;
}

.btn-signup span {
  color: #fff;
}

.search-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.img-mobile {
  height: 150px !important;
  max-height: 150px !important;
}

h4 {
  font-size: 16px !important;
  font-weight: 300 !important;
}

h3 {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Number of lines to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 170px !important;
  max-width: 170px !important;
  font-size: 13px;
  font-weight: 300;
}

.truncate-description {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* Number of lines to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* min-width: 170px !important;
  max-width: 170px !important; */
  font-size: 13px;
  font-weight: 300;
}

.compare-close-btn {
  position: relative;
  float: right;
  right: 0px;
  border: 1px solid;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  text-align: center;
  vertical-align: middle;
  line-height: 1em;
  top: 0px;
  font-weight: 900;
  cursor: pointer;
  z-index: 9999999;
}

.product-price {
  font-weight: 400;
  font-size: 18px;
}

.mobile-card:hover {
  border: 1px solid #48afff;
  cursor: pointer;
}

.search-form-outer {
  display: flex !important;
  flex-grow: 1 !important;
  justify-content: start !important;
}

.search-form {
  margin: 0;
  width: 100%;
  max-width: 418px;
  position: relative;
}

.search-form-input {
  background-color: #fff;
  height: 52px !important;
  color: #748a98 !important;
  font-size: 14px !important;
  font-weight: normal !important;
  height: 40px !important;
  margin: 0 !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  box-sizing: inherit !important;
  padding: 0.6rem 1rem !important;
  width: 100% !important;
  appearance: none !important;
}

.brands-dropdown-menu {
  margin-top: 15px !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  min-width: 250px !important;
  border-radius: 0px !important;
  max-height: 400px !important;
  overflow: auto !important;
}

.dropdown-menu {
  margin-top: 9px !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  min-width: 250px !important;
  border-radius: 5px !important;
  max-height: 400px !important;
  overflow: auto !important;
}

#search-brand-list {
  list-style-type: none;
  max-height: 200px !important;
  overflow: auto !important;
}

.img-thumbnail {
  width: 100px !important;
  height: 100px !important;
}

.flex-1 {
  flex: 1;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.sub-header {
  list-style-type: none;
}

.sub-header li {
  display: inline-block;
  min-width: 160px;
  padding: 7px 40px;
  max-width: 160px;
}

.sub-header li:hover {
  background-color: rgba(215, 217, 219, .3);
  border-bottom: 3px solid #48afff;
  cursor: pointer;
}

.sub-header li span {
  margin-top: 5px;
  font-size: 12px;
}

#collasible-nav-dropdown-currencies,
#collasible-nav-dropdown-brands {
  color: white !important;
}

.sub-header li div svg {
  fill: #48afff !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%2348afff%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%2348afff%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e") !important;
}

.custom-bg-clr {
  background: #f1f3f6 !important;
}

h1 {
  font-size: 20px !important;
  font-weight: 900 !important;
}

h2 {
  font-size: 18px !important;
  font-weight: 700 !important;
}

.mr-2 {
  margin-right: 5px !important;
}

.price-card {
  min-width: 150px !important;
  border-radius: 15px !important;
  margin-bottom: 0px !important;
}

.price-card.active {
  border: 3px solid #48afff !important;
}

.storage-card {
  min-width: 150px !important;
  border-radius: 15px !important;
  margin-right: 5px !important;
  margin-bottom: 0px !important;
  margin-bottom: 0.5rem !important;
}

.storage-card.active {
  border: 3px solid #48afff !important;
}

.storage-card:hover {
  border: 3px solid #48afff !important;
  cursor: pointer;
}

.prominent-card {
  border-radius: 15px !important;
  border: 1px solid #e5e5e5 !important;
}

.prominent-card span {
  color: black !important;
}

.prominent-card-outer {
  border-radius: 0px !important;
  border: 0px solid !important;
  background-color: transparent !important;
  box-shadow: none !important;
  margin-bottom: 0px !important;
}

.dv-star-rating {
  margin-bottom: -15px !important;
}

.description-card {
  border-radius: 0px !important;
  border: 0px solid !important;
}

.specification-card {
  border-radius: 0px !important;
  border: 0px solid !important;
}

.lg-react-element {
  display: flex;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.unboxing-videos-card {
  border-radius: 0px !important;
  border: 0px solid !important;
}

.video-detail-container {
  margin: 0 0 5px 0px;
}

.video-title {
  font-weight: 600;
  overflow: hidden;
  display: block;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  white-space: normal;
}

.video-thumbnail {
  width: 120px;
  height: 90px;
  border-radius: 15px;
}

.reviews-card {
  border-radius: 0px !important;
  border: 0px solid !important;
}

.customList {
  list-style-type: none;
  line-height: 2em;
  font-size: 16px;
}

.customList li a {
  text-decoration: none;
  color: black;
  font-weight: 400 !important;
}

.customList li a:hover {
  color: blueviolet;
}

.logo-header {
  font-size: 36px !important;
}

.get-notification-text {
  font-size: 20px !important;
  margin-bottom: 15px;
}

#inputGroup-sizing-lg i:hover {
  color: blue !important;
  cursor: pointer;
}

.my-card-title {
  height: 33px;
  background-color: #f1f2f6;
  color: #303f4a;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  padding: 7px 22px;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.my-card-title .icon {
  width: 46px;
  height: 46px;
  background-color: #fff;
  border: 3px solid #e9eef2;
  border-radius: 50%;
  margin-right: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 8px;
}

.my-card-title .icon img {
  max-width: 100% !important;
  height: auto !important;
}

.specification-table tr {
  border-style: hidden !important;
}

.ml-15 {
  margin-left: 15px;
}

.list-group-item {
  border: none !important;
  border-bottom: 0px solid #e3e3e3 !important;
}

.user-image-with-first-latter {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 900;
}

/* .compare-list {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  min-width: 250px !important;
  border-radius: 5px !important;
  max-height: 400px !important;
  overflow: auto !important;
  list-style-type: none;
  padding-left: 0px !important;
} */

.compare-dropdown {
  position: relative;
}

.compare-list {
  position: absolute;
  top: 100%;
  left: 0;
  list-style-type: none;
  background-color: #fff;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 400px;
  /* Set a max height if needed */
  overflow-y: auto;
  min-width: 250px !important;
}

.compare-list li {
  padding: 5px;
  cursor: pointer;
}

.compare-list li:hover {
  background-color: #f5f5f5;
}

.text-right {
  text-align: right !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.rounded {
  border-radius: 100px !important;
  --bs-border-radius: 100px !important;
}

.search-input-results-dropdown .dropdown-menu {
  min-width: 420px !important;
}

@media (max-width: 767px) {
  .search-input-results-dropdown .dropdown-menu {
    width: 100%;
  }

  .truncate {
    max-width: 100% !important;
  }
}

@media (max-width: 1023px) {
  .d-flex {
    display: flex !important;
  }
}

.navbar-toggler:focus {
  outline: none !important;
  box-shadow: none !important;
}

.navbar-toggler {
  border: 0px !important;
}

.navbar-toggler-icon {
  width: 1em !important;
  height: 1em !important;
}

.p-0 {
  padding-left: 0px impr !important;
  padding-right: 0px !important;
}

.border-radius-15 {
  border-radius: 15px !important;
}

#mob-search {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 99;
}

.overflow-auto {
  overflow: auto !important;
}

#mob-search .searchbox {
  position: sticky;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #f1f2f6;
  padding: 10px 15px;
  background: #fff;
}

#mob-search .searchbox input {
  font-size: 15px;
  line-height: 20px;
  border: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#mob-search .searchbox .search {
  padding: 0;
  font-size: 20px;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#mob-search .searchbox .back {
  padding: 0;
  font-size: 20px;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#mob-search .trending {
  padding: 0px 15px;
}

#mob-search .history {
  padding: 0px 15px;
}

#mob-search .trending span.badge {
  margin-right: 5px;
}

#mob-search .mobile-search-dropdown {
  margin-top: 5px;
}

#mob-search .mobile-search-dropdown div.list-item {
  padding: 0px 15px;
}

#mob-search .mobile-search-dropdown div.list-item:hover {
  background-color: rgba(215, 217, 219, .3);
}

.video-light-gallery-item div.lg-react-element {
  padding-left: 0px !important;
}

#my-drawer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 99;
}

#my-drawer .drawer-header {
  position: sticky;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #f1f2f6;
  padding: 10px 15px;
  background: #fff;
}

.drawer-show {
  display: block !important;
}

.drawer-hide {
  display: none !important;
}

.drawer-body-list {
  list-style-type: none;
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.drawer-body-list div.justify-content-space-between {
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
}

.drawer-body-list div.custom-padding {
  padding: 10px 15px !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.custom-dropdown-menu-mob .list-group .list-group-item:hover {
  background-color: #f5f5f5;
}

.border-bottom {
  border-bottom: 1px solid #e7eaf3 !important;
}

.border-color-1 {
  border-color: #dddddd !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2rem !important;
}

.section-title {
  position: relative;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.font-size-25 {
  font-size: 1.56275rem;
}

.pr-5,
.px-5 {
  padding-right: 2rem !important;
}

.btn-primary-dark-w {
  color: #333e48;
  background-color: #39e96c;
  border-color: #39e96c;
}

.btn-primary-dark-w:hover {
  background-color: #333e48;
  border-color: #333e48;
  color: #fff;
}

.text-lh-23 {
  line-height: 1.438rem;
}

.mb-6,
.my-6 {
  margin-bottom: 2.5rem !important;
}

.text-decoration-on {
  text-decoration: underline;
}

.text-blue {
  color: #0062bd !important;
}

.mb-10,
.my-10 {
  margin-bottom: 4.5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 3.5rem !important;
}

.mb-lg-0,
.my-lg-0 {
  margin-bottom: 0 !important;
}

.mr-xl-6,
.mx-xl-6 {
  margin-right: 2.5rem !important;
}

.direct-contact-card {
  border: 0px !important;
  border-radius: 0px !important;
}

.leave-a-message-card {
  border: 0px !important;
  border-radius: 0px !important;
}

.font-weight-200 {
  font-weight: 200 !important;
}

#collasible-nav-dropdown-currencies,
#collasible-nav-dropdown-brands {
  font-weight: 200 !important;
}

/* Vertical scrollbar */
/* ::-webkit-scrollbar {
  height: 10px; 
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #333;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

::-webkit-scrollbar-horizontal {
  height: 10px;
}

::-webkit-scrollbar-thumb:horizontal {
  background-color: #333;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover:horizontal {
  background-color: #555;
} */

.carousel-control-prev-icon {
  /* background-color: #ff5733 !important; */
  /* Example color: Orange */
}

/* Customize the right indicator background color */
.carousel-control-next-icon {
  /* background-color: #33ff57 !important; */
  /* Example color: Green */
}

@keyframes marquee_bar {
  0% {
    transform: translate(0, 0)
  }

  100% {
    transform: translate(-100%, 0)
  }
}

.anouncement-bar {
  position: relative;
  height: 38px;
  background-color: #68BBE3;
  overflow: hidden
}

.anouncement-bar .li-list {
  display: inline-block;
  padding: .7rem 3rem .7rem;
  color: #fff;
  margin-bottom: initial;
  white-space: nowrap;
  font-weight: 700;
}

.anouncement-bar .spot-anim {
  padding-left: 100%;
  position: absolute;
  transform: translateX(-100%);
  will-change: transform;
  white-space: nowrap;
  animation: marquee_bar 30s linear infinite
}

.anouncement-bar .spot-anim:hover {
  animation-play-state: paused
}

.anouncement-bar span {
  color: #fff
}

@media (max-width: 768px) {
  .h-200 {
    height: 200px !important;
    /* Apply the height of 200px on mobile devices */
  }

  .mobile-card {
    text-align: center;
    justify-content: center;
  }

  .logo-text {
    display: none !important;
  }

  .nav-auth-buttons {
    margin-right: 25px !important;
  }
}

.nav-auth-buttons {
  margin-right: 15px;
}

.justify-content-space-between {
  justify-content: space-between;
}

p {
  font-weight: 400 !important;
}

.icheck-list {
  list-style-type: none;
  padding-left: 0px;
}

.colors-card {
  max-width: 120px !important;
  border-radius: 15px !important;
  margin-bottom: 0px !important;
}

.colors-card.active {
  border: 3px solid #48afff !important;
}

.colors-card:hover {
  border: 3px solid #48afff !important;
  cursor: pointer;
}

.color-variant-text {
  font-size: 10px;
  font-weight: 600;
  margin-top: 3px;
  text-align: center;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.offcanvas-backdrop {
  opacity: 0.7 !important;
}

.offcanvas {
  --bs-offcanvas-padding-x: 0rem !important;
  --bs-offcanvas-padding-y: 0rem !important;
}

.sb-user-box {
  display: flex;
  background: #0E86D4;
  padding: calc(30px * 2) 30px;
}

.sb-user-box ul {
  list-style-type: none;
  padding-left: 0px !important;
}

.sb-user-box ul li {
  line-height: 2rem;
}

.font-14 {
  font-size: 14px;
  font-weight: bold;
}

.offcanvas.show {
  overflow-y: auto !important;
}

.sidebar-offcanvas .accordion .accordion-item {
  border-top: 0px !important;
}

.topbar-dark {
  background: #4f5467
}

.topbar {
  background: #0E86D4;
}

.main-navigation-list {
  list-style-type: none;
  padding: 0px !important;
}

.main-navigation-list li a {
  color: black !important;
  font-weight: 700;
  line-height: 25px !important;
}

.my-accordion-item {
  border: none !important;
}

.my-accordion-item .accordion-header button {
  padding: 15px 0px !important;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  -webkit-transition: all .1s ease-out;
  -moz-transition: all .1s ease-out;
  -o-transition: all .1s ease-out;
  -ms-transition: all .1s ease-out;
  transition: all .1s ease-out;
}

.btn-outline-secondary {
  color: #fff !important;
  background: transparent !important;
  border-color: #dee2e6;
}

.navbar-header {
  background: transparent !important;
}

.price-expected-keyword {
  font-size: 13px !important;
}

.mobile-card {
  position: relative;

  .overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &:hover .overlay {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ec-compare:before {
  content: "\67";
}

.about-sec p,
.privacy-sec p {
  color: #000;
  line-height: 28.32px;
  margin-bottom: 15px;
  font-size: 18px;
}

.privacy-heading {
  font-size: 48px !important;
}

.privacy-sec h4 {
  line-height: 28px !important;
  font-size: 22px !important;
  font-weight: 500 !important;
}

.privacy-sec ul li {
  color: black !important;
  font-weight: 400 !important;
}

.btn-primary {
  background-color: #0E86D4 !important;
  border-color: #0E86D4 !important;
}

.h-60 {
  height: 60% !important;
}

.language-select {
  display: flex;
  align-items: center;
}

.language-select select {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0px !important;
  color: white !important;
}

.language-select select option {
  color: black !important;
}

.favt-span:hover {
  cursor: pointer;
}

.icheck-list li label span {
  vertical-align: middle !important;
  margin-left: 2px !important;
}

.maxWidth-100 {
  max-width: 100% !important;
}

.list-page .static-info {
  display: grid;
  grid-template-columns: 24% 76%;
  padding: 30px 0
}

@media(max-width: 767px) {
  .list-page .static-info {
    grid-template-columns: 100%;
    padding: initial
  }
}

.list-page .warranty-info {
  grid-template-columns: 15% 85%
}

@media(max-width: 767px) {
  .list-page .warranty-info {
    grid-template-columns: 100%
  }
}

.list-page li {
  list-style-type: none;
  position: relative;
  padding-inline-start: 30px;
  color: #909090
}

.list-page .static-categories {
  border-right: 1.5px solid rgba(7, 18, 27, .4);
  display: flex;
  flex-direction: column
}

.list-page .static-categories .category-item {
  font-size: 1.3rem;
  position: relative;
  transition: .3s;
  padding-inline-start: 30px;
  color: #909090;
  margin-top: calc(30px / 2)
}

.list-page .static-categories .category-item::before {
  content: "";
  background-image: url(https://static.priceoye.pk/images/social-icons/list-star-white.svg);
  height: 16px;
  width: 16px;
  background-size: contain;
  position: absolute;
  left: 2px;
  background-repeat: no-repeat;
  top: 2px
}

.list-page .static-categories .category-item:hover,
.list-page .static-categories .category-item:focus {
  color: #fba338;
  cursor: pointer;
  transition: .3s
}

.list-page .static-categories .category-item:hover::before,
.list-page .static-categories .category-item:focus::before {
  background-image: url(https://static.priceoye.pk/images/social-icons/list-star.svg)
}

@media(max-width: 767px) {
  .list-page .static-categories {
    display: none
  }
}

.list-page .static-detail {
  padding-inline-start: 20px
}

.list-page .static-detail li::before {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid rgba(7, 18, 27, .6);
  left: 13px;
  top: 6px
}

@media(max-width: 767px) {
  .list-page .static-detail {
    padding-inline-start: initial
  }

  .trending-heading {
    padding-left: 15px;
  }

  .latest-heading {
    padding-left: 15px !important;
  }

  .upcoming-heading {
    padding-left: 15px !important;
  }
}

.list-page .section-info {
  padding: calc(30px / 2) 0px 20px
}

.list-page .arrow-bullet {
  margin-right: 8px;
  font-size: 1.8rem
}

.list-page .static-btn {
  color: #fba338;
  border: 1px solid #fba338;
  padding: calc(30px / 3);
  border-radius: 8px;
  display: inline-block
}

.list-page .static-btn:focus {
  color: #fba338
}

@media(max-width: 767px) {
  .list-page .terms-box .faq-btn {
    margin: calc(30px / 2) 0 0
  }
}

.direction-rtl {
  direction: rtl !important;
}

.direction-ltr {
  direction: ltr !important;
}

.navbar {
  --bs-navbar-padding-y: 0px !important;
  padding: 0px !important;
}

.language-dropdown-item a {
  color: white !important;
}

.language-dropdown-item .dropdown-menu a {
  color: black !important;
}

.btn-outline-light:hover {
  background: transparent !important;
}

.offcanvas-backdrop {
  width: 100% !important;
  height: 100% !important;
}

.mailbox {
  left: -161px !important;
}

.notify .heartbit {
  position: absolute !important;
  top: -20px !important;
  right: -4px !important;
  height: 25px !important;
  width: 25px !important;
  z-index: 10 !important;
  border: 5px solid #e46a76 !important;
  border-radius: 70px !important;
  -moz-animation: heartbit 1s ease-out infinite !important;
  /* corrected */
  -o-animation: heartbit 1s ease-out infinite !important;
  /* corrected */
  -webkit-animation: heartbit 1s ease-out infinite !important;
  /* corrected */
  animation: heartbit 1s ease-out infinite !important;
  /* added */
}

@keyframes heartbit {
  0% {
    transform: scale(0.75);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(0.75);
  }

  60% {
    transform: scale(1);
  }

  80% {
    transform: scale(0.75);
  }

  100% {
    transform: scale(0.75);
  }
}