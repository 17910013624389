    @media(max-width: 767px) {
        #order-header {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            background: #48afff;
            height: 57px;
            padding: 10px
        }

        #order-header .order-step {
            flex: 1 1 30%;
            margin-bottom: 4px
        }

        #order-header .text-display {
            display: none
        }
    }

    @media(min-width: 768px) {
        #order-header {
            display: none
        }
    }

    #product-info {
        margin-bottom: 15px
    }

    @media(min-width: 768px) {
        #product-info {
            padding: 0px
        }
    }

    #product-info .card {
        padding: calc(30px / 2) calc(30px / 2)
    }

    @media(max-width: 767px) {
        #product-info .card {
            padding: calc(30px / 2) calc(30px / 2)
        }
    }

    #product-info .apply-coupon-section {
        background-color: #f9f9f9;
        padding: 5px 25px
    }

    #product-info .cart-img-container {
        border: 1px solid #d6d6d6;
        position: relative;
        margin-bottom: auto
    }

    #product-info .cart-img-container .cross-icon {
        position: absolute;
        top: -10px;
        left: -10px;
        z-index: 1
    }

    #product-info .list-group {
        list-style: none;
        margin-bottom: 0px
    }

    #product-info .list-group-item {
        flex-direction: row
    }

    #product-info .coupon-section {
        background-color: #f9f9f9
    }

    #product-info .coupon-form-header {
        display: flex;
        flex-direction: row;
        white-space: nowrap
    }

    #product-info .coupon-form-header label,
    #product-info .coupon-form-header span {
        flex: 1%
    }

    #product-info .coupon-form-header label {
        margin-bottom: 1.2rem
    }

    #product-info .coupon-form {
        display: flex;
        margin-top: .5rem
    }

    #product-info .coupon-form input {
        font-size: 1.2rem;
        margin-right: .5rem;
        margin-bottom: 1.2rem;
        background-color: #ebebeb
    }

    #product-info .apply-cpn-btn {
        color: #fff;
        background-color: #48afff;
        border-color: #48afff;
        margin-bottom: 1.2rem
    }

    #product-info .applied-btn {
        color: #43a3ee;
        background-color: #e5f2fc;
        border-color: #e5f2fc;
        margin-bottom: 1.2rem
    }

    #product-info .cpn-discount {
        display: flex
    }

    #product-info .text-line {
        text-decoration: line-through
    }

    #product-info .cpn-message {
        padding: 0px 25px;
        background-color: #f9f9f9
    }

    #product-info .cart-video-container amp-youtube>button {
        background: none
    }

    #product-info .estimate-img {
        background: url(https://static.priceoye.pk/images/estimate-icon.svg) left/contain no-repeat;
        width: auto;
        height: 30px;
        display: flex;
        align-items: center
    }

    #product-info .estimate-img span {
        font-size: 1.2rem;
        left: 25px;
        position: relative;
        padding: 0 calc(30px / 2)
    }

    #product-info .print-btn {
        margin: .5rem 0rem 4.5rem 0rem
    }

    #product-info .accordian-title {
        position: relative
    }

    #product-info .accordian-title .label {
        position: relative;
        font-size: 1.8rem;
        color: #48afff;
        left: 20px;
        cursor: pointer
    }

    #product-info .accordian-title .label::before {
        content: "+";
        color: #48afff;
        position: absolute;
        top: 50%;
        left: -19px;
        font-size: 1.8rem;
        transform: translateY(-50%)
    }

    #product-info .accordian-title .content {
        position: relative;
        background: #fff;
        height: 0;
        font-size: 20px;
        text-align: justify;
        overflow: hidden;
        transition: .5s
    }

    #product-info .accordian-title.active .content {
        height: auto
    }

    #product-info .accordian-title.active .label::before {
        content: "-";
        font-size: 1.8rem
    }

    #product-info .redeem {
        display: flex;
        margin-bottom: 5px
    }

    #product-info .redeem_label {
        flex: 1;
        align-self: center;
        text-align: left
    }

    #product-info .switch {
        position: relative;
        display: inline-block;
        width: 62px;
        height: 30px
    }

    #product-info .switch input {
        display: none
    }

    #product-info .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #dbdbdb;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px
    }

    #product-info .slider:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        left: 5px;
        bottom: 7px;
        background-color: #fff;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%
    }

    #product-info input:checked+.slider {
        background-color: #48afff
    }

    #product-info input:focus+.slider {
        box-shadow: 0 0 1px #48afff
    }

    #product-info input:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(38px)
    }

    #product-info .slider:after {
        content: "";
        color: #fff;
        display: block;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        font-size: 10px;
        font-family: Verdana, sans-serif
    }

    @media(max-width: 767px) {
        #product-info .print-btn {
            display: none
        }
    }

    .cart-item-detail-container {
        flex: 1;
        padding-left: calc(30px / 2)
    }

    .cart-product-title {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1.1rem
    }

    .cart-product-title h6 {
        flex: 1 0 calc(100% - 20px);
        margin-bottom: auto
    }

    .cart-product-title a {
        height: 20px;
        width: 20px
    }

    .cart-item-detail {
        font-size: 1.3rem;
        color: #404040;
        display: flex;
        flex-direction: column
    }

    .price-total {
        flex-direction: row;
        justify-content: space-between
    }

    #order-page {
        overflow: hidden;
        margin: 30px calc(30px * 2);
        position: relative
    }

    @media(max-width: 767px) {
        #order-page {
            margin: 0px 0 calc(30px * 3)
        }
    }

    #order-info .card {
        padding: 30px
    }

    @media(max-width: 767px) {
        #order-info .card {
            padding: calc(30px / 2)
        }
    }

    #order-info .d-flex {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap
    }

    @media(max-width: 767px) {
        #order-info .d-flex {
            flex-direction: row
        }
    }

    #order-info label,
    #order-info span {
        flex: auto
    }

    #order-info label {
        margin-bottom: 0
    }

    #order-info h4 {
        margin: 1rem 0rem
    }

    #order-info i {
        font-size: 1rem;
        color: #909090;
        flex: 1 0 100%
    }

    #order-info input,
    #order-info select,
    #order-info textarea {
        margin: .5rem 0rem 1.8rem 0rem
    }

    #order-info .cod-buy-btn {
        width: auto;
        margin: 0 auto
    }

    #order-info .text-op {
        opacity: .3
    }

    @media(max-width: 767px) {
        #order-info .buy-now-box {
            display: none
        }
    }

    @media(max-width: 767px) {
        #space-section {
            margin: 45px 45px
        }
    }

    #payment-info .p-method-box {
        display: flex;
        align-items: center;
        padding: 10px
    }

    #payment-info .p-icon {
        margin-right: 10px
    }

    #payment-info .delivery-text {
        flex: 1
    }

    #payment-info label {
        font-size: 1.2rem
    }

    @media(max-width: 767px) {
        #payment-info label {
            font-size: 1.4rem
        }
    }

    #payment-info input[type=radio]:checked:after {
        width: 18px;
        height: 18px;
        border-radius: 18px;
        top: -1px;
        left: -1px;
        position: relative;
        background-color: #fba338;
        content: "";
        display: inline-block;
        visibility: visible;
        border: 2px solid #fff
    }

    #payment-info .box-border:focus-within {
        box-shadow: inset -1px 0 0 #fba338, inset 0 -1px 0 #fba338, inset 1px 0 0 #fba338, inset 0 1px 0 #fba338
    }

    #payment-info .checked-box-border {
        box-shadow: inset -1px 0 0 #fba338, inset 0 -1px 0 #fba338, inset 1px 0 0 #fba338, inset 0 1px 0 #fba338
    }

    #payment-info .p-method-bottom {
        margin-bottom: 1rem
    }

    @media(max-width: 767px) {
        #payment-info .buy-now-box {
            display: none
        }
    }

    #payment-info .box-border .box-padding {
        padding: 0px 10px 10px 10px
    }

    #payment-info .accordionMenu .content .delivery-conainer {
        background: #fba338;
        color: #fff;
        border-color: #753903;
        padding: 15px
    }

    #payment-info .accordionMenu .content {
        display: none
    }

    #payment-info .accordionMenu .visible-content-openparcel {
        display: block
    }

    #payment-info .accordionMenu .visible-content-selfpickup {
        display: block
    }

    #payment-info .accordionMenu .visible-content-selfpickup-safa {
        display: block
    }

    #payment-info .accordionMenu .visible-content-selfpickup-giga {
        display: block
    }

    #payment-info .accordionMenu .visible-content-selfpickup-ufone {
        display: block
    }

    #payment-info .accordionMenu .visible_content_creditcard {
        display: block
    }

    #payment-info .accordionMenu .visible_content_safepay {
        display: block
    }

    #payment-info .accordionMenu .visible_content_installment {
        display: block
    }

    #pickup_dropdown select {
        appearance: auto
    }

    #order-common-box {
        border: 1px solid #e5e5e5;
        border-radius: 8px;
        margin: 10px 0px;
        padding: calc(30px / 2)
    }

    #order-common-box .title-box {
        display: flex;
        justify-content: space-between
    }

    #order-steps {
        z-index: 1
    }

    #order-steps ul {
        margin-bottom: 0rem
    }

    #order-steps .progressbar {
        display: flex;
        counter-reset: step;
        margin-top: 2rem;
        padding-left: 0px !important;
    }

    #order-steps .progressbar li {
        display: flex;
        position: relative;
        list-style: none;
        z-index: 999999999;
        width: 100%;
        text-align: center
    }

    #order-steps .progressbar li span {
        align-self: center;
        margin: auto
    }

    #order-steps .progressbar li::before {
        content: counter(step);
        counter-increment: step;
        color: #48afff;
        width: 54px;
        height: 54px;
        border: 1px solid #48afff;
        outline: 4px solid #48afff;
        outline-offset: -7px;
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center
    }

    #order-steps .progressbar li::after {
        background: #48afff;
        content: "";
        position: absolute;
        width: 100%;
        height: 34px;
        top: 12px;
        right: -5px;
        z-index: -99999999923;
        opacity: .18
    }

    #order-steps .progressbar li:last-child:after {
        border-radius: 40px
    }

    #order-steps .progressbar li.active:before {
        background: url(https://static.priceoye.pk/images/delivery-bar-icon.png) left top/contain no-repeat;
        border: 0px solid #fff;
        outline: 0px;
        content: ""
    }

    #order-steps .progressbar li.active:after {
        background: #48afff;
        opacity: 1
    }

    @media(max-width: 767px) {
        #order-steps .progressbar {
            display: none
        }
    }

    #login-form .login-form-sec .number-input>input {
        width: 37%
    }

    @media(max-width: 767px) {
        #login-form .login-form-sec .number-input>input {
            width: 50%
        }
    }

    #login-form .login-form-sec .verify-box {
        display: flex
    }

    #login-form .login-form-sec .verify-box .img-verify-txt {
        align-items: center;
        display: inline-flex;
        margin-left: 15px
    }

    #login-form .login-form-sec .verify-box .img-verify-txt span {
        margin: 5px
    }

    #verify-code-form .login-form-sec .number-input input {
        width: 6%;
        margin: 0px 10px 0px 0px
    }

    @media(max-width: 767px) {
        #verify-code-form .login-form-sec .number-input input {
            width: 12%;
            margin: 0px 5px 0px 0px
        }
    }

    #verify-code-form .login-form-sec .resend-code {
        display: flex;
        margin-top: calc(30px / 2)
    }

    @media(max-width: 767px) {
        #verify-code-form .login-form-sec .resend-code {
            justify-content: center
        }
    }

    .error-message {
        display: inline;
        color: #f53d3d
    }

    .error-message p {
        margin: 0px
    }

    @media(min-width: 768px) {
        .delivery-flex {
            display: flex
        }
    }

    .delivery-flex .display-box {
        display: flex;
        flex: auto
    }

    @media(max-width: 767px) {
        .delivery-flex .display-box {
            flex-direction: row
        }
    }

    .delivery-flex .order-form-input {
        flex: 1 0 50%;
        overflow-wrap: anywhere
    }

    .delivery-flex .order-form-input:first-child {
        padding-right: 10px
    }

    @media(max-width: 767px) {
        .delivery-flex .order-form-input:first-child {
            padding-right: 0px
        }
    }

    .hr-line {
        height: 6px;
        background-color: #ebebeb;
        margin-bottom: 3rem
    }

    #thankyou-page {
        overflow: hidden;
        margin: 0 0 calc(30px * 5);
        position: relative
    }

    @media(min-width: 768px) {
        #thankyou-page {
            margin: 30px calc(30px * 2)
        }
    }

    #thankyou-page .customer-info .order-no {
        display: flex;
        position: relative;
        bottom: 100px;
        padding: 0px 20px;
        flex-direction: column;
        font-size: 2rem;
        color: #fff
    }

    #thankyou-page .customer-info .receipt-img {
        border-radius: 4px;
        background: #48afff url(https://static.priceoye.pk/images/reciept-header.png) left/cover no-repeat;
        color: #fff;
        display: flex;
        flex-direction: column;
        font-size: 2rem;
        height: 150px;
        justify-content: center;
        padding: 20px
    }

    @media(max-width: 767px) {
        #thankyou-page .customer-info .receipt-img {
            font-size: 1.4rem;
            height: 85px
        }
    }

    #thankyou-page .customer-info .contact-delivery-box {
        padding: 20px
    }

    #thankyou-page .customer-info .contact-delivery-box .contact-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap
    }

    #thankyou-page .customer-info .contact-delivery-box .contact-box .contact-detail {
        flex: 1 1 25%;
        margin: 5px
    }

    #thankyou-page .customer-info .contact-delivery-box .contact-box .contact-detail span,
    #thankyou-page .customer-info .contact-delivery-box .contact-box .contact-detail label {
        font-size: 1.3rem
    }

    #thankyou-page .customer-info .contact-delivery-box .detail-section {
        flex-direction: column;
        column-count: 2;
        margin-bottom: 5px
    }

    #thankyou-page .customer-info .contact-delivery-box .address span,
    #thankyou-page .customer-info .contact-delivery-box .address label,
    #thankyou-page .customer-info .contact-delivery-box .order-notes span,
    #thankyou-page .customer-info .contact-delivery-box .order-notes label {
        font-size: 1.3rem
    }

    #thankyou-page .customer-info .contact-delivery-box .product-detail {
        display: flex
    }

    #thankyou-page .customer-info .contact-delivery-box .product-detail .product-group {
        flex: 1 0 50%
    }

    #thankyou-page .customer-info .contact-delivery-box .product-detail .product-group .product-group-item {
        display: flex
    }

    @media(max-width: 767px) {
        #thankyou-page .customer-info .contact-delivery-box .product-detail .product-group .product-group-item {
            margin-top: 10px
        }
    }

    #thankyou-page .customer-info .contact-delivery-box .product-detail .product-group .product-group-item .product-img-container {
        align-self: flex-start;
        border: 1px solid #ccc;
        padding-top: 5px
    }

    #thankyou-page .customer-info .contact-delivery-box .product-detail .order-price {
        align-self: center;
        flex: 1 0 50%
    }

    @media(min-width: 768px) {
        #thankyou-page .customer-info .rcpt-mb-img {
            display: none
        }
    }

    @media(max-width: 767px) {
        #thankyou-page .customer-info .rcpt-wb-img {
            display: none
        }
    }

    #thankyou-page h4 {
        margin: calc(30px / 2) 0
    }

    .ty-title {
        display: flex;
        margin-bottom: 0px
    }

    .ty-icon {
        margin-left: calc(30px / 3)
    }

    #advance_request {
        margin-bottom: 13px
    }

    @media print {
        body {
            background: #fff
        }

        #thankyou-page .card {
            background: #f1f3f6
        }

        #thankyou-page .card-white {
            background: #fff
        }

        #thankyou-page .delivery-estimates {
            display: none
        }

        #thankyou-page .print-btn {
            display: none
        }

        #product-info .cart-img-container {
            border: initial
        }

        #order-stickybar {
            display: none
        }
    }

    .pd-visible {
        display: block
    }

    .error-box {
        font-size: 1.2rem;
        padding: 10px;
        text-align: center
    }

    #packing-img-section .packBox {
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        padding: calc(30px / 2)
    }

    @media(max-width: 767px) {
        #packing-img-section .packBox {
            justify-content: space-between;
            flex-wrap: wrap
        }
    }

    #packing-img-section .packBox .packimg-box {
        display: flex;
        flex: 0 0 24%;
        margin: 5px;
        width: 100%;
        height: 100%
    }

    #packing-img-section .packBox .packimg-box amp-img {
        border-radius: 10px
    }

    @media(max-width: 767px) {
        #packing-img-section .packBox .packimg-box {
            margin: 3px;
            width: 47%
        }
    }

    #packing-video-section .packVideoBox {
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        padding: calc(30px / 2)
    }

    @media(max-width: 767px) {
        #packing-video-section .packVideoBox {
            flex-direction: column
        }
    }

    #packing-video-section .packVideoBox .packvideo-box {
        flex: 0 0 24%;
        margin: 5px
    }

    #footer {
        display: none
    }

    #order-stickybar {
        position: absolute;
        padding-bottom: calc(30px * 3)
    }

    #order-stickybar .sticky-box {
        display: flex;
        justify-content: space-between;
        position: fixed;
        bottom: 65px;
        left: 0;
        width: 100%;
        background: #fff;
        z-index: 999999;
        padding: calc(30px / 3) calc(30px / 2);
        border-bottom: 1px solid rgba(215, 217, 219, .3)
    }

    #order-stickybar .sticky-box .pd-box {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap
    }

    #order-stickybar .sticky-box .order-btn {
        align-self: center
    }

    @media(min-width: 768px) {
        #order-stickybar {
            display: none
        }
    }

    #warranty-section .mb-section {
        display: flex;
        flex-direction: row
    }

    #warranty-section .mb-section .replace-section {
        flex: 1 0 33%;
        padding: 20px;
        border-right: 1px solid #d6d6d6
    }

    @media(max-width: 767px) {
        #warranty-section .mb-section .replace-section {
            padding: 10px
        }
    }

    #warranty-section .mb-section .warnty-img-section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: .5rem
    }

    #warranty-section .mb-section .warranty-text {
        display: flex;
        flex-direction: row;
        justify-content: center
    }

    @media(max-width: 767px) {
        #warranty-section .mb-section .warranty-text {
            text-align: center;
            font-size: 1rem;
            line-height: 1
        }
    }

    .order-apply-column {
        padding-right: 0px !important;
    }

    @media (max-width: 767px) {
        .order-apply-column {
            margin-top: 15px !important;
            padding-left: 0px !important;
            padding-right: 0px !important;
            background-color: #43a3ee;
        }
    }